import React from 'react'

import company from '../images/service/1.png'
import serviceTwo from '../images/service/2.png'
import serviceThree from '../images/service/3.png'
import serviceFour from '../images/service/4.png'
import serviceFive from '../images/service/5.png'
import serviceSix from '../images/service/6.png'
import serviceSeven from '../images/service/7.png'
import serviceEight from '../images/service/8.png'
import serviceNine from '../images/service/9.png'
import serviceTen from '../images/service/10.png'
import serviceEleven from '../images/service/11.png'
import serviceTwele from '../images/service/12.png'
import serviceThriten from '../images/service/13.png'
import serviceFourteen from '../images/service/14.png'
import serviceFifteen from '../images/service/15.png'
import serviceSixten from '../images/service/16.png'
import serviceSeventen from '../images/service/17.png'
import serviceEithtin from '../images/service/18.png'
import serviceNightin from '../images/service/19.png'
import serviceTwnety from '../images/service/20.png'


//icons
import { FaArrowRightLong } from "react-icons/fa6";

const AllServices = () => {
    return (
        <>
            <div className='flex items-center justify-center -z-0'>

                <div className='grid  grid-cols-2 md:grid-cols-4  gap-x-4 md:gap-x-6 gap-y-10 md:gap-y-16 w-full'>

                    <div className='h-full group'>
                        <div className=' flex flex-col gap-y-6 justify-between items-center h-full cursor-pointer' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center group-hover:scale-105 transition-transform  duration-300'>
                                <img src={company} className='w-[25%] object-cover rounded-md ' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[14px]  animate-bounce duration-1000' data-aos='flip-up' data-aos-duration='2000'>Company Registration</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center '>"Every big story begins with a single signature"</p>
                                <div>
                                    <button
                                        data-aos="flip-up"
                                        data-aos-duration="2000"
                                        className="group-hover:rotate-[360deg] transition-transform duration-300 flex items-center gap-x-2 font-ocr uppercase border border-gray-300 rounded-full p-1.5  md:p-2 bg-white shadow-md drop-shadow-md"
                                    >
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceTwo} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Strategy & Analytics</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Success thrives where insight meets action."</p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceThree} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>AD shoots</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[11px] italic font-[poppins] text-center'>"Every frame tells a story."</p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceFive} className='w-[25%] object-cover rounded-md dr  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Digital Marketing</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Meet your audience."</p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceFour} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Television & FM Ad Releases</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Stand out where it counts."</p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceSix} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Website & App Development</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Your brand’s digital home"</p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ----------------------------------- */}


                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceSeven} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Branding & Design</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Your brand is more than a logo—it’s a legacy”</p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceEight} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>CRM & ERP Support</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[11px] italic font-[poppins] text-center'>"Efficiency is an experience."  </p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceNine} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Graphics Designing</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Design that speaks louder than words."</p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceTen} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Video Content Creation</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Transform raw footage into a cinematic masterpiece."</p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceEleven} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>WhatsApp Chatbot Integration</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Speak to your customers, any time, any place."  </p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceFourteen} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Sales & Marketing Support</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Sales build lasting connections."  </p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceTwele} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Movie Promotion</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Promoting your film, from trailer to red carpet."</p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceThriten} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Outdoor Promotion</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Where your brand reaches beyond the screen."</p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceFifteen} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Television Channel Setup</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Your content, your channel."   </p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceSixten} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Event Management </h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>“Every event, unforgettable.” </p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceSeventen} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Franchise Management</h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Bring your brand to every corner."  </p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceEithtin} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>PR & Media Outreach </h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Connecting you with the Tech world”  </p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                
                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceNightin} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Product Distribution Across the Globe  </h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"Bringing your products to the world." </p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5 md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-full group'>
                        <div className='flex flex-col gap-y-6 justify-between items-center h-full' data-aos='fade-up' data-aos-duration='3000'>
                            <div className='flex justify-center items-center'>
                                <img src={serviceTwnety} className='w-[25%] object-cover rounded-md  group-hover:scale-105 transition-transform  duration-300' alt='brand' />
                            </div>
                            <div className='flex flex-col items-center gap-y-1 w-full'>
                                <h2 className='font-medium text-center text-[12px]  md:text-[16px] animate-bounce duration-1000'>Infrastructure Setup & Training </h2>
                                <div className='border border-gray-300 w-full'></div>
                                <p className='text-[10px] md:text-[11px] italic font-[poppins] text-center'>"The best-built brands are made to last."   </p>
                                <div>
                                    <button className='flex items-center gap-x-20 font-ocr uppercase border border-gray-300 rounded-full p-1.5  md:p-2 bg-white shadow-md drop-shadow-md'>
                                        <span><FaArrowRightLong className='text-[8px] md:text-[12px]' /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AllServices
